import { Avatar, Stack, Typography } from '@mui/joy'
import { Button, CardMedia } from '@mui/material'
import { COLOR, CONTACT, IMAGE } from '../../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const ContactZone = () => {
    return (
        <Stack
            bgcolor={COLOR.white}
            p={1}
            gap={2}
            borderRadius={10}
            sx={{
                borderTopLeftRadius: 50
            }}
            mt={4}
        // maxHeight={300}
        // height={250}
        >
            <Stack
                direction={window.innerWidth < 800 ? "column" : "row"}
            >
                <Avatar
                    sx={{
                        transform: "translate(-10px, -20px)",
                        width: 110,
                        height: 110,
                        bgcolor: COLOR.yellow
                    }}
                >
                    <CardMedia
                        component={"img"}
                        src={IMAGE.messageIcone}
                        alt="Devenez partenaire de Karlo Car. Plateforme de vente et location de voiture au mali"
                    />
                </Avatar>
                <Stack>
                    <Typography
                        fontWeight={700}
                        color='primary'
                        level='h3'
                    >
                        Suivez-nous sur nos réseaux sociaux !
                    </Typography>
                    <Typography>
                        Pour rester informé de toutes nos dernières actualités, nouveautés et événements, n'oubliez pas de nous suivre sur nos différents réseaux sociaux.
                    </Typography>
                </Stack>
            </Stack>

            <Stack
                direction={"row"}
                justifyContent={"center"}
                gap={2}
                flexWrap={"wrap"}
                sx={{
                    "& > a": {
                        width: 80,
                        aspectRatio: "1/1",
                        background: COLOR.falseBlack,
                        fontSize: 40,
                        borderRadius: 15
                    }
                }}
            >
                <Button
                    href={CONTACT.whatsapp}
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faWhatsapp} />
                </Button>
                <Button
                    href={CONTACT.titkok}
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faTiktok} />
                </Button>
                <Button
                    href={CONTACT.instagram}
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </Button>
                <Button
                    href={CONTACT.instagram}
                    target='_blank'
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </Button>

            </Stack>
        </Stack>

    )
}

export default ContactZone