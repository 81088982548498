import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import { EffectCoverflow, Autoplay, Navigation, Thumbs, Parallax, Pagination } from 'swiper/modules';
import { Box, Card, Typography } from '@mui/joy';
import { CardMedia } from '@mui/material';

const ImageZone = ({ images, isALouer, idVoiture, idMarque, nomMarque, modele }) => {
    return (
        <Box
            sx={{
                minWidth: 350,
            }}
        >

            <Swiper
                slidesPerView={1}
                navigation
                pagination
                modules={[EffectCoverflow, Autoplay, Navigation, Thumbs, Parallax, Pagination]}
                effect='coverflow'
                autoplay={{
                    delay: 5000
                }}
                loop
            >
                {
                    images && images.map((value, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                height: 500,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                style={{
                                    objectFit: "contain",
                                    height: "100%",
                                }}
                                src={`${process.env.REACT_APP_API_URL}/${isALouer == 'true' ? "voitureLouer" : "voitureVendre"}/${idVoiture}/images/${value}`}
                                alt={`${modele} disponible chez Karlo Car. Location et achat de voiture à Bamako`}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <Box
                sx={{
                    position: "absolute",
                    mt: -10,
                    zIndex: 2,
                    p: 0,
                    width: 80,
                    height: 80,
                    ":hover": {
                        "& > *": {
                            transform: `rotateY(360deg)`,
                        },
                        "& > * > *:last-child": {
                            display: "block"
                        },
                        "& > * > *:first-child": {
                            display: "none"
                        }
                    },
                }}
            >
                <Card
                    sx={{
                        p: 0,
                        transition: 'all .3s',
                        width: 80,
                        height: 80,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "& > *:last-child": {
                            display: "none"
                        },
                    }}
                >
                    <CardMedia
                        component={"img"}
                        src={`${process.env.REACT_APP_API_URL}/marque/${idMarque}/image`}
                        sx={{
                            width: 80,
                            height: 80,
                            objectFit: "contain"
                        }}
                        alt={`${nomMarque} disponible chez Karlo Car. Location et achat de voiture à Bamako`}
                    />
                    <Typography
                        sx={{
                            textWrap: "wrap",
                            fontWeight: 700
                        }}
                    >
                        {nomMarque}
                    </Typography>
                </Card>
            </Box>
        </Box>
    )
}

export default ImageZone