import { faCarAlt, faCommentDots, faHomeAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import logo from "../assets/images/logo924x272.png"
import lexusWalPaper from "../assets/images/lexus.jpg"
import corolaWallPaper from "../assets/images/corolaWallPaper.webp"
import kiaWallPaper from "../assets/images/kiaWallPaper.webp"
import mercedesWallPaper from "../assets/images/mercedesWallPaper.jpg"
import rangeRoverWallPaper from "../assets/images/rangeRoverWallPaper.webp"
import toyotaLogo from "../assets/images/toyotaLogo.png"
import bmwX6 from "../assets/images/bmwX6.jpg"
import kiaLogo from "../assets/images/kiaLogo.webp"
import mercedesLogo from "../assets/images/mercedesLogo.png"
import BMWLogo from "../assets/images/BMWLogo.svg.png"
import HyundaiLogo from "../assets/images/HyundaiLogo.jpg"
import opelLogo from "../assets/images/opelLogo.jpg"
import rangeRoverFooterback from "../assets/images/rangeRoverFooterback.jpg"
import tourAfrique from "../assets/images/Tour_d'Afrique.jpg"
import androidLogo from "../assets/images/android-logo.png"
import boiteVitesse from "../assets/images/boite-vitesse.png"
import essenceIcon from "../assets/images/essence-icon.jpg"
import typeVoitureIcone from "../assets/images/type-voiture-icone.jpg"
import carDoorIcone from "../assets/images/car-door-icone.webp"
import faceWithStarEyes from "../assets/images/face-with-star-eyes.png"
import bmwX from "../assets/images/bmw-x.webp"
import messageIcone from "../assets/images/message-icone.avif"

export const IMAGE = {
    defaultImage: logo,
    lexusWalPaper: lexusWalPaper,
    corolaWallPaper: corolaWallPaper,
    kiaWallPaper: kiaWallPaper,
    mercedesWallPaper: mercedesWallPaper,
    rangeRoverWallPaper: rangeRoverWallPaper,
    toyotaLogo: toyotaLogo,
    bmwX6: bmwX6,
    kiaLogo: kiaLogo,
    mercedesLogo: mercedesLogo,
    BMWLogo: BMWLogo,
    HyundaiLogo: HyundaiLogo,
    opelLogo: opelLogo,
    rangeRoverFooterback: rangeRoverFooterback,
    tourAfrique: tourAfrique,
    androidLogo: androidLogo,
    boiteVitesse: boiteVitesse,
    essenceIcon: essenceIcon,
    typeVoitureIcone: typeVoitureIcone,
    carDoorIcone: carDoorIcone,
    faceWithStarEyes: faceWithStarEyes,
    bmwX: bmwX,
    messageIcone: messageIcone,
}

export const COLOR = {
    primaryBlue: "#0b6df6",
    darkBlue: "#1e4763",
    lightGrey: "#d1d1d5",
    darkGrey: "#6c8899",
    lightblue: "#80d7ff",
    falseBlack: "#041824",
    white: "#FFFFFF",
    black: "#000000",
    green: "#298b50",
    yellow: "#c0ad4a",
    red: "#cd0000",
}

export const CONTACT = {
    tel: [
        "+223 94 45 46 27",
    ],
    email: "contact@karlo-car.com",
    whatsapp: "+22366035300",
    facebook: "facebook.com",
    instagram: "https://www.instagram.com/karlocarml223/",
    titkok: "https://www.tiktok.com/@karlocar",
}

export const PAGE_LIST = [
    {
        pageName: "Accueil",
        icon: faHomeAlt,
        link: "/"
    },
    {
        pageName: "Garage",
        icon: faCarAlt,
        link: "/garage"
    },
    {
        pageName: "Contact",
        icon: faCommentDots,
        link: "/contact"
    },
    {
        pageName: "A  propos",
        icon: faInfoCircle,
        link: "/about"
    },
]