import { Button, Card, CardContent, CardCover, Input, Stack, Switch, Typography } from '@mui/joy'
import { COLOR, IMAGE } from '../../constants/constants'
import { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '@fontsource/saira-stencil-one';
import { blue } from '@mui/material/colors'

const categorieList = [
    {
        img: IMAGE.rangeRoverWallPaper,
        text: "Range Rover"
    },
    {
        img: IMAGE.lexusWalPaper,
        text: "Lexus"
    },
    {
        img: IMAGE.corolaWallPaper,
        text: "Toyota"
    },
    {
        img: IMAGE.kiaWallPaper,
        text: "Kia"
    },
    {
        img: IMAGE.mercedesWallPaper,
        text: "Mercedes"
    },
]

const CardCategorie = ({ img, categorie, isBig = false }) => {

    return (
        <Card
            sx={{
                width: "100%",
                borderRadius: 0,
                // borderColor: COLOR.falseBlack,
                filter: `grayscale(${isBig ? 0 : 1})`,
                borderTop: 0,
                borderBottom: 0,
                transform: `scale(${isBig ? 1.4 : 1})`,
                transition: `all 0.2s`,
                ":hover": {
                    minWidth: 250,
                    filter: `grayscale(0)`,
                },
                "& button": {
                    display: "none"
                },
                "&:hover button": {
                    display: "initial"
                }
            }}
        >
            <CardCover>
                <img
                    src={img || IMAGE.kiaWallPaper}
                    alt={`KIA disponible chez Karlo Car. Location et achat de voiture à Bamako`}
                />
            </CardCover>
            <CardCover sx={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.945), rgba(255, 255, 255, 0))`,
                opacity: 1
            }} />
            <CardContent
                sx={{
                    justifyContent: "center",

                }}
            >
                <Button
                    sx={{
                        gap: 2,
                        width: "fit-content",
                        alignSelf: "center"
                    }}
                >
                    {categorie || "A louer"}
                </Button>
            </CardContent>
        </Card>
    )
}

const Welcome = () => {
    const [cardIndex, setcardIndex] = useState(0);

    const [typeVoiture, settypeVoiture] = useState(null);
    const [prixMax, setprixMax] = useState(null);

    const inputRef = useRef < HTMLInputElement | null > (null);

    setTimeout(() => {
        setcardIndex(cardIndex > 4 ? 0 : cardIndex + 1);
    }, [3000]);

    return (
        <Card
            sx={{
                width: "100vw",
                height: "100vh",
                minHeight: "100vh",
                bgcolor: "transparent",
                p: 0,
                border: 0,
                borderRadius: 0,
                overflow: "hidden",
                mt: -6
            }}
        >
            <CardContent
                sx={{
                    position: "absolute",
                    zIndex: 3,
                    ml: "50%",
                    transform: "translateX(-50%)",
                    top: 100,
                    width: 360,
                    textAlign: "center"
                }}
            >
                <Typography
                    textColor={"white"}
                    fontSize={20}
                    fontWeight={"bold"}
                >
                    Quelque soit vos goûts en automobile,
                </Typography>
                <Typography
                    textColor={COLOR.primaryBlue}
                    fontFamily={"Saira Stencil One !important"}
                    fontSize={80}
                    sx={{
                        m: -3,
                    }}
                >
                    Karlo Car
                </Typography>
                <Typography
                    textColor={"white"}
                    fontWeight={"bold"}
                    fontSize={30}
                >
                    est le
                    <Typography
                        textColor={blue[300]}
                    > site </Typography>
                    idéal pour vous
                </Typography>
            </CardContent>
            <CardContent
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 3,
                    bgcolor: COLOR.falseBlack,
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    bottom: 20,
                    p: 1,
                    borderRadius: 15,
                    ml: "50%",
                    transform: "translateX(-50%)"
                }}
            >
                <Stack
                    gap={2}
                    alignItems="center"
                    height={"fit-content"}
                >
                    <Input
                        startDecorator={
                            <Button
                                sx={{
                                    borderRadius: 8,
                                    height: "80%"
                                }}
                            >Votre budget</Button>
                        }
                        endDecorator={
                            <Typography
                                level='body-sm'
                            >FCFA</Typography>
                        }
                        sx={{
                            borderRadius: 10,
                            height: 50,
                            fontSize: 22,
                            width: 300,
                            fontWeight: "bold"
                        }}
                        slotProps={{
                            input: {
                                ref: inputRef,
                                min: 0,
                                step: 1000,
                            },
                        }}
                        type='number'
                        value={prixMax}
                        onChange={({ target }) => setprixMax(target?.value)}
                    />
                    <Stack
                        direction={"row"}
                        gap={2}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <Typography
                                textColor={COLOR.white}
                            >A vendre</Typography>
                            <Switch
                                size='lg'
                                variant='plain'
                                onChange={() => {
                                    settypeVoiture(
                                        !!typeVoiture && typeVoiture != "a vendre"
                                            ? "a vendre"
                                            : "a louer"
                                    )
                                }}
                            />
                            <Typography
                                textColor={COLOR.white}
                            >A Louer</Typography>
                        </Stack>
                        <Button
                            sx={{
                                bgcolor: COLOR.primaryBlue
                            }}
                            endDecorator={
                                <FontAwesomeIcon icon={faSearch} />
                            }
                            onClick={() => window.open(`/garage/${typeVoiture || null}/${prixMax || null}`, "_self")}
                        >Search</Button>
                    </Stack>
                </Stack>
            </CardContent>

            <CardContent
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Stack
                    height={"100%"}
                    direction={"row"}
                    sx={{
                    }}
                >
                    {
                        categorieList.map((value, index) => (
                            <CardCategorie
                                img={value.img}
                                categorie={value.text}
                                key={index}
                                isBig={cardIndex === index}
                            />
                        ))
                    }
                </Stack>
            </CardContent>
            <CardCover sx={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.945), rgba(255, 255, 255, 0))`,
                opacity: 1
            }} />
        </Card >
    )
}

export default Welcome