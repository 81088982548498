import "./App.css"
import "@fontsource/ubuntu"
import "@fontsource/poppins"
import "./index.css"
import Router from './components/Router'
import { BrowserRouter } from "react-router-dom"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import { Box } from "@mui/joy"

const App = () => {
  return (
    <BrowserRouter>
      <Box mt={6} />
      <Header />
      <Router />
      <Footer/>
    </BrowserRouter>
  )
}

export default App