import axios from "axios";
import { ADD_RESERVATION_T } from "../../../constants/objectType";

export const addReservation = (telephone: string, voitureLouer: any) => {
    return new Promise((resolve, reject) => {
        axios.postForm(
            `${process.env.REACT_APP_API_URL}/demandeReservation/AddDemandeReservation`,
            {
                telephone: telephone,
                voitureLouer: voitureLouer
            }
        ).then(
            ({ data }: { data: ADD_RESERVATION_T }) => {
                console.log('====================================');
                console.log("Reservation effectué", data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (res) => {
                console.log('====================================');
                console.error(res);
                console.log('====================================');

                reject(res);
            }
        )
    });
}