import { Avatar, Button, Card, CardContent, Divider, FormControl, FormLabel, Input, Stack, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'
import { COLOR, IMAGE } from '../../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const PartenariaZone = () => {
    return (
        <Stack
            bgcolor={COLOR.white}
            p={1}
            gap={2}
            borderRadius={10}
            sx={{
                borderTopLeftRadius: 50
            }}
        // maxHeight={300}
        // height={250}
        >
            <Stack
                direction={window.innerWidth < 800 ? "column" : "row"}
            >
                <Avatar
                    sx={{
                        transform: "translate(-10px, -20px)",
                        width: 100,
                        height: 100,
                        p: 1,
                        bgcolor: COLOR.yellow
                    }}
                >
                    <CardMedia
                        component={"img"}
                        src={IMAGE.faceWithStarEyes}
                        alt="Contactez karlo car. plateforme de vente et location de voiture au mali"
                    />
                </Avatar>
                <Stack>
                    <Typography
                        fontWeight={700}
                        color='primary'
                        level='h3'
                    >
                        Vous disposez d'une voiture à vendre?
                    </Typography>
                    <Typography>
                        Nous sommes ravis de vous offrir l’opportunité de rejoindre notre réseau de partenaires et d’augmenter la visibilité de votre commerce ! En remplissant notre formulaire de partenariat, vous pourrez bénéficier de nombreux avantages conçus pour propulser votre entreprise vers de nouveaux sommets.
                    </Typography>
                </Stack>
            </Stack>

            <Typography
                level='h4'
                color='primary'
            >
                Pourquoi devenir partenaire ?
            </Typography>

            <Stack
                direction={"row"}
                justifyContent={"center"}
                gap={2}
                flexWrap={"wrap"}
                sx={{
                    "& > *": {
                        width: window.innerWidth < 500 ? "100%" : '40%',
                        maxWidth: 400,
                        minWidth: 300,
                    }
                }}
            >
                <Card>
                    <CardContent>
                        <Typography
                            fontWeight={700}
                        >
                            Augmentez votre visibilité
                        </Typography>
                        <Divider />
                        <Typography>
                            Profitez de notre plateforme pour atteindre un large public de potentiels acheteurs.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography
                            fontWeight={700}
                        >
                            Optimisez vos ventes
                        </Typography>
                        <Divider />
                        <Typography>
                            Accédez à des outils et des fonctionnalités conçus pour maximiser vos opportunités de vente.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography
                            fontWeight={700}
                        >
                            Support dédié
                        </Typography>
                        <Divider />
                        <Typography>
                            Bénéficiez d'un accompagnement personnalisé pour chaque étape de votre parcours de vente.
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography
                            fontWeight={700}
                        >
                            Augmentez votre visibilité
                        </Typography>
                        <Divider />
                        <Typography>
                            Profitez de notre plateforme pour atteindre un large public de potentiels acheteurs.
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>

            <form
                style={{
                    alignSelf: "center",
                }}
            >
                <Stack
                    // height={200}
                    direction={"row"}
                    // bgcolor={COLOR.lightblue}
                    gap={1}
                    border={`1px solid ${COLOR.lightGrey}`}
                    borderRadius={10}
                    overflow={"hidden"}
                    flexWrap={"wrap"}

                >
                    <CardMedia
                        image={IMAGE.bmwX}
                        title="Logo Android"
                        component={"img"}
                        sx={{
                            height: 300,
                            width: window.innerWidth < 900 ? "100%" : 300,
                            aspectRatio: "1/1",
                        }}
                    />
                    <Stack
                        gap={2}
                        p={1}
                        alignSelf={"center"}
                        maxWidth={400}
                    >
                        <Typography>
                            Remplissez le formulaire maintenant pour commencer votre collaboration avec nous !
                        </Typography>
                        <FormControl>
                            <FormLabel
                            >Votre nom et prenom</FormLabel>
                            <Input id='nomPrenom' />
                        </FormControl>
                        <FormControl>
                            <FormLabel
                            >Votre numero whatsapp</FormLabel>
                            <Input id='nomPrenom' />
                        </FormControl>
                        <Button
                            endDecorator={
                                <FontAwesomeIcon icon={faPaperPlane} />
                            }
                        >
                            Rejoindre le reseau
                        </Button>
                    </Stack>

                </Stack>
            </form>
        </Stack>

    )
}

export default PartenariaZone