import { Card, CardCover, Link } from "@mui/joy"
import { COLOR } from "../../constants/constants"

const MarqueCard = ({ img, width, height, hover, borderColor, onClick, id, nomMarque }) => {
    return (
        <Link
            sx={{
                textDecoration: "none !!important",
            }}
            href={id && `/garage/${null}/${null}/${id}`}
        >
            <Card
                sx={{
                    width: width || 200,
                    height: height || 200,
                    borderRadius: 15,
                    p: 1,
                    transition: `all .3s`,
                    border: `1px solid ${borderColor || 'white'}`,
                    ":hover": hover || {
                        boxShadow: `0px 0px 15px ${COLOR.lightblue}`,
                        transform: `scale(1.03)`
                    }
                }}
                onClick={() => onClick && onClick()}
            >
                <CardCover>
                    <img
                        src={img}
                        alt={`${nomMarque} disponible chez Karlo Car. Karlo Car Location et achat de voiture à Bamako`}
                        style={{
                            objectFit: "contain"
                        }}
                    />
                </CardCover>
            </Card>
        </Link>
    )
}

export default MarqueCard