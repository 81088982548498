import { Avatar, Button, Card, CardContent, CardCover, CircularProgress, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCarAlt } from "@fortawesome/free-solid-svg-icons"

const LoadingCard = ({ width, height }) => {
    return (
        <Card
            sx={{
                width: window.innerWidth < 500 ? 280 : width || 250,
                height: window.innerWidth < 500 ? 280 : height || 250,
                borderRadius: 25,
                p: 1,
                transition: `all .3s`,
                border: `4px solid ${COLOR.falseBlack}`,
            }}
        >
            <CardCover
                sx={{
                    bgcolor: "grey"
                }}
            >
            </CardCover>
            <CardContent
                sx={{
                    direction: "row",
                    justifyContent: "space-between",
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                >
                    <Card
                        sx={{
                            borderRadius: "10px",
                            width: 35,
                            height: 35,
                            p: 0,
                        }}

                    >
                        <CardCover>
                            <Avatar
                                variant="solid"
                            >
                                <FontAwesomeIcon
                                    sx={{
                                        color: COLOR.falseBlack
                                    }}
                                    icon={faCarAlt}
                                />
                            </Avatar>
                        </CardCover>

                    </Card>


                    <Button
                        sx={{
                            p: 1
                        }}
                        color="neutral"
                    > FCFA</Button>
                </Stack>

                <CircularProgress 
                    sx={{
                        alignSelf:"center"
                    }}
                    size="lg"
                />

                <Typography
                    textAlign={"center"}
                    fontWeight="bold"
                    sx={{
                        bgcolor: COLOR.falseBlack,
                        p: 1,
                        borderRadius: 10,
                        color: COLOR.white,
                    }}
                >
                    Chargement en cours ...
                </Typography>
            </CardContent>
        </Card>
    )
}

export default LoadingCard