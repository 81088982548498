import axios from "axios";
import { TYPE_VOITURE_T } from "../../../constants/objectType";

/**
 * Retrieves a list of all type voiture from the API.
 *
 * @returns {Promise<TYPE_VOITURE_T[]>} A promise that resolves with an array of type reservoir objects.
 * @example
 * getAllTypeReservoire().then((typeReservoirs) => {
 *   console.log(typeReservoirs); // Array of TYPE_VOITURE_T objects
 * }).catch((error) => {
 *   console.error(error);
 * });
 */
export const getAllTypeVoiture = (): Promise<TYPE_VOITURE_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/typeReservoire/getAllTypeReservoir`).then(
            ({ data }: { data: TYPE_VOITURE_T[] }) => {
                console.log('====================================');
                console.log("La liste des type de voiture", data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (res) => {
                console.log('====================================');
                console.error(res);
                console.log('====================================');

                reject(res);
            }
        )
    });
}