import { Box, Button, ButtonGroup, Card, CardCover, Input, Link, Stack, Textarea } from '@mui/joy'
import { COLOR, CONTACT, IMAGE } from '../../constants/constants'
import ContactSocialGroup from '../ContactSocialGroup/ContactSocialGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import QRCode from 'react-qr-code'


const ContactZone = () => {
    return (
        <Stack>
            <Stack
                direction={"row"}
                gap={1}
                // height={110}
                alignItems={"center"}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "fit-content"
                    }}
                >
                    <img
                        style={{
                            objectFit: "contain",
                            width: "100%"
                        }}
                        src={IMAGE.defaultImage}
                        alt='Logo de Karlo Car. Location et achat de voiture à Bamako'
                    />

                </Box>
                <Card
                    sx={{
                        aspectRatio: "1/1",
                        // height: "100%",
                        width: "30%",
                        // height: "100%",
                        borderRadius: 15,
                        p: 1
                    }}
                >
                    <Link>
                        <img
                            src={IMAGE.androidLogo}
                            style={{
                                objectFit: "contain",
                                // height: "100%",
                                width: "100%"
                            }}
                        />
                    </Link>
                </Card>

            </Stack>

            <Stack
                direction={"row"}
                gap={1}
                flexWrap={window.innerWidth < 900 ? "wrap" : "nowrap"}
                justifyContent={window.innerWidth < 900 && "center"}
            >
                <ContactSocialGroup
                    mt={0}
                    iconWidth={window.innerWidth < 500 && 80}
                    iconSize={window.innerWidth < 500 && 54}
                />
                <ButtonGroup
                    variant='soft'
                    sx={{
                        width: "100%",
                        "& > *": {
                            p: 0,
                            "& > *": {
                                textWrap: "nowrap",
                                fontSize: window.innerWidth > 900 && 11,
                                color: COLOR.falseBlack,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }
                        }
                    }}
                >
                    <Button
                        fullWidth
                    >
                        <Link
                            href={`tel:${CONTACT.tel}`}
                        >
                            {CONTACT.tel}
                        </Link>
                    </Button>
                    <Button
                        fullWidth
                    >
                        <Link
                            href={`mailto:${CONTACT.email}`}
                        >
                            {CONTACT.email}
                        </Link>
                    </Button>
                </ButtonGroup>
            </Stack>

            <Stack
                direction={"row"}
                gap={1}
                alignItems={"center"}
            >
                <Card
                    sx={{
                        aspectRatio: "1/1",
                        height: "100%",
                        p: 0
                    }}
                >
                    <CardCover>
                        <QRCode
                            value='https://karlocar.com'
                        />
                    </CardCover>
                </Card>

                <form
                    style={{
                        width: "100%",
                        maxWidth: window.innerWidth < 500 ? "60%" : "100%"
                    }}
                >
                    <Stack
                        gap={1}
                        borderRadius={10}
                        // height={"100%"}
                        justifyContent={"space-between"}
                        sx={{
                            // maxWidth: window.innerWidth < 500 ? "88%" : "100%"
                        }}
                    >
                        <Input
                            fullWidth
                            placeholder='Votre numero de telephone'
                            required
                        />
                        <Textarea
                            fullWidth
                            placeholder='Votre message'
                            required
                        />
                        <Button
                            endDecorator={
                                <FontAwesomeIcon icon={faPaperPlane} />
                            }
                        >
                            Soumettre un message
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </Stack >
    )
}

export default ContactZone