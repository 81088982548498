import { Route, Routes } from 'react-router-dom'
import Accueil from '../../Pages/accueil'
import Garage from '../../Pages/garage'
import Voiture from '../../Pages/Voiture'
import Contact from '../../Pages/contact'

const Router = () => {
  return (
    <Routes >
      <Route path="/*" element={<Accueil />} />

      <Route path="/garage/*" element={<Garage />} />
      <Route path="/garage/:typeVoiture/:prixMax/:idMarque" element={<Garage />} />
      <Route path="/garage/:typeVoiture/:prixMax" element={<Garage />} />

      <Route path="/voiture/*" element={<Voiture />} />
      <Route path="/voiture/:idVoiture/:isALouer" element={<Voiture />} />
      
      <Route path="/contact/*" element={<Contact />} />
    </Routes>
  )
}

export default Router