import { Container } from '@mui/joy'
import PartenariaZone from './PartenariaZone'
import ContactZone from './ContactZone'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <Container
            sx={{
                my: 8,
            }}
        >
            <Helmet>
                <title>Contactez Karlo Car. Site web de location et achat de voiture à Bamako.</title>
                <meta name="description" content="Contactez Karlo Car. Site web de location et achat de voiture à Bamako." />
            </Helmet>
            <PartenariaZone />
            <ContactZone />
        </Container>
    )
}

export default Contact