import { Stack } from "@mui/joy"
import MarqueCard from "../../components/MarqueCard/MarqueCard"
import { useEffect, useState } from "react"
import { getAllMarques } from "../../functions/api/marque/getAllMarques"
import { GET_ALL_MARQUES_T, USE_STATE_T } from "../../constants/objectType"

const MarqueZone = () => {
  const [marqueListe, setmarqueListe]: [marqueListe: GET_ALL_MARQUES_T[], setmarqueListe: USE_STATE_T] = useState([]);

  useEffect(
    () => {
      getAllMarques().then(
        data => {
          setmarqueListe(data);
        }
      )
    },
    []
  )

  return (
    <Stack
      height={"fit-content"}
      py={2}
      gap={2}
      direction={"row"}
      sx={{
        overflowX: "scroll",
      }}
    >
      {
        marqueListe.map((value, index) => (
          <MarqueCard
            nomMarque={value.nomMarque}
            img={`${process.env.REACT_APP_API_URL}/marque/${value.idMarque}/image`}
            height={undefined}
            hover={undefined}
            width={undefined}
            borderColor={undefined}
            onClick={undefined}
            id={value.idMarque}
            key={index}
          />
        ))
      }
    </Stack>
  )
}

export default MarqueZone