import { faBarsProgress, faCalendarAlt, faCarOn, faCheck, faFilter, faRotateBack, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, ButtonGroup, FormControl, FormLabel, Input, Stack } from '@mui/joy'
import { useEffect, useState } from 'react'
import { MenuItem, Select } from '@mui/material'
import { filterParams_T } from '..'
import { GET_ALL_MARQUES_T, TYPE_RESERVOIR_T, TYPE_VOITURE_T, USE_STATE_T, VOITURE_A_LOUER_T, VOITURE_A_VENDRE_T } from '../../../constants/objectType'
import { getAllMarques } from '../../../functions/api/marque/getAllMarques'
import { getAllTypeReservoire } from '../../../functions/api/typeReservoire/getAllTypeReservoire'
import { getAllTypeVoiture } from '../../../functions/api/typeVoiture/getAllTypeVoiture'
import { COLOR } from '../../../constants/constants'
import MarqueCard from '../../../components/MarqueCard/MarqueCard'
import { handlefilterData } from './functions/handleFilterData'

const FiltreMaximifierZone = (
    {
        filterParams,
        filterParamsDefaultValue,
        setfilterParams,
        voitureAvendreListeOriginal,
        voitureAlouerListeOriginal,
        setvoitureAvendreListe,
        setvoitureAlouerListe,
    }:
        {
            filterParams: filterParams_T,
            filterParamsDefaultValue : filterParams_T,
            setfilterParams: USE_STATE_T,
            voitureAvendreListeOriginal: VOITURE_A_VENDRE_T[],
            voitureAlouerListeOriginal: VOITURE_A_LOUER_T[],
            setvoitureAvendreListe: USE_STATE_T,
            setvoitureAlouerListe: USE_STATE_T
        }
) => {
    const [isOpened, setisOpened] = useState(false);
    const [showAllMarque, setshowAllMarque] = useState(false);
    const [marqueListe, setmarqueListe]: [marqueListe: GET_ALL_MARQUES_T[], setmarqueListe: USE_STATE_T] = useState([]);
    const [reservoireListe, setreservoireListe]: [reservoireListe: TYPE_RESERVOIR_T[], setreservoireListe: USE_STATE_T] = useState([]);
    const [typeVoitureListe, settypeVoitureListe]: [typeVoitureListe: TYPE_VOITURE_T[], settypeVoitureListe: USE_STATE_T] = useState([]);

    useEffect(
        () => {
            getAllMarques().then(
                data => {
                    setmarqueListe(data);
                }
            );
            getAllTypeReservoire().then(
                data => {
                    setreservoireListe(data);
                }
            );
            getAllTypeVoiture().then(
                data => {
                    settypeVoitureListe(data);
                }
            );
        },
        []
    )

    return (
        <Stack
            position={"fixed"}
            bottom={20}
            right={20}
            alignItems={"flex-end"}
            gap={1}
            sx={{
                color: COLOR.darkBlue
            }}
            zIndex={2}
        >
            {
                isOpened && (
                    <Stack
                        bgcolor={COLOR.white}
                        width={360}
                        height={400}
                        borderRadius={10}
                        p={1}
                        gap={3}
                        border={`2px solid ${COLOR.falseBlack}`}
                    >
                        <Button
                            endDecorator={
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                        alignSelf: "flex-end"
                                    }}
                                />
                            }
                            onClick={() => setisOpened(!isOpened)}
                        >
                            Fermer
                        </Button>

                        <Stack
                            gap={2}
                            sx={{
                                overflowY: "scroll",
                            }}
                        >
                            <Box>
                                <FormLabel>Quel est votre tranche de prix?</FormLabel>

                                <Stack
                                    direction={"row"}
                                    gap={1}
                                >
                                    <Input
                                        type='number'
                                        placeholder='prix minimum'
                                        startDecorator={"XOF"}
                                        slotProps={{ input: { min: 0, step: 1000 } }}
                                        value={filterParams.prixMin}
                                        onChange={({ target }) => setfilterParams({ ...filterParams, prixMin: target.value })}
                                    />
                                    <Input
                                        type='number'
                                        placeholder='prix maximum'
                                        startDecorator={"XOF"}
                                        slotProps={{ input: { min: 0, step: 1000 } }}
                                        value={filterParams.prixMax}
                                        onChange={({ target }) => setfilterParams({ ...filterParams, prixMax: target.value })}
                                    />
                                </Stack>
                            </Box>

                            <Stack
                                direction={"row"}
                                width={"100%"}
                                gap={1}
                            >
                                <FormControl
                                    sx={{
                                        width: "64%",
                                    }}
                                >
                                    <FormLabel>Quel modele vous interesse?</FormLabel>
                                    <Input
                                        fullWidth
                                        placeholder='Une Rav4 2020 ?'
                                        endDecorator={
                                            <FontAwesomeIcon icon={faCarOn} />
                                        }
                                        value={filterParams.nomVoiture}
                                        onChange={({ target }) => setfilterParams({ ...filterParams, nomVoiture: target.value })}
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        width: 120
                                    }}
                                >
                                    <FormLabel>Année?</FormLabel>
                                    <Input
                                        placeholder='2020 ?'
                                        endDecorator={
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        }
                                        type='number'
                                        slotProps={{
                                            input: {
                                                min: 1800,
                                                max: 2025
                                            }
                                        }}
                                        value={filterParams.annee}
                                        onChange={({ target }) => setfilterParams({ ...filterParams, annee: target.value })}
                                    />
                                </FormControl>
                            </Stack>

                            <FormControl>
                                <FormLabel>Decouvrez notre catalogue de marque</FormLabel>

                                <Stack
                                    direction={"row"}
                                    flexWrap={"wrap"}
                                    justifyContent={"space-between"}
                                    height={showAllMarque ? "fit-content" : 120}
                                    sx={{
                                        overflowY: "hidden"
                                    }}
                                >
                                    {
                                        marqueListe.map((value, index) => (
                                            <MarqueCard
                                                img={`${process.env.REACT_APP_API_URL}/marque/${value.idMarque}/image`}
                                                width={100}
                                                height={100}
                                                hover={{
                                                    transform: `scale(1.02)`
                                                }}
                                                id={undefined}
                                                nomMarque={value.nomMarque}
                                                key={index}
                                                borderColor={filterParams.idMarque == value.idMarque ? COLOR.primaryBlue : undefined}
                                                onClick={() => setfilterParams({ ...filterParams, idMarque: filterParams.idMarque == value.idMarque ? null : value.idMarque })}
                                            />
                                        ))
                                    }
                                </Stack>
                                <Button
                                    onClick={() => setshowAllMarque(!showAllMarque)}
                                    endDecorator={
                                        <FontAwesomeIcon icon={faBarsProgress} />
                                    }
                                    sx={{
                                        bgcolor: "transparent",
                                        color: COLOR.darkGrey,
                                        alignSelf: "flex-end",
                                        borderBottom: `1px solid`,
                                        borderRadius: 0,
                                        ":hover": {
                                            bgcolor: "transparent",
                                            color: COLOR.darkGrey,
                                        },
                                        p: 0
                                    }}
                                >Voir {!showAllMarque ? "toutes les" : "moins de"} les marques </Button>
                            </FormControl>


                            <Stack
                                direction={"row"}
                                gap={1}
                            >
                                <FormControl>
                                    <FormLabel>Quel type de service ?</FormLabel>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => setfilterParams({ ...filterParams, isAVendre: filterParams.isAVendre ? null : true })}
                                            variant={filterParams.isAVendre ? "solid" : "outlined"}
                                        >Vente</Button>
                                        <Button
                                            onClick={() => setfilterParams({ ...filterParams, isAVendre: filterParams.isAVendre == false ? null : false })}
                                            variant={filterParams.isAVendre == false ? "solid" : "outlined"}
                                        >Location</Button>
                                    </ButtonGroup>
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Quel type de boite?</FormLabel>
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => setfilterParams({ ...filterParams, isAutomatique: filterParams.isAutomatique ? null : true })}
                                            variant={filterParams.isAutomatique == true ? "solid" : "outlined"}
                                        >Automatic</Button>
                                        <Button
                                            onClick={() => setfilterParams({ ...filterParams, isAutomatique: filterParams.isAutomatique == false ? null : false })}
                                            variant={filterParams.isAutomatique == false ? "solid" : "outlined"}
                                        >Manuel</Button>
                                    </ButtonGroup>
                                </FormControl>
                            </Stack>

                            <FormControl>
                                <FormLabel>Quel type de reservoir?</FormLabel>
                                <Select
                                    placeholder={"Essence ou Gaz Oil ?"}
                                    value={filterParams.idTypeReservoire}
                                    onChange={({ target }) => setfilterParams({ ...filterParams, idTypeReservoire: target.value })}
                                >
                                    <MenuItem
                                        value="aucun"
                                    >Tout</MenuItem>
                                    {
                                        reservoireListe.map((value, index) => (
                                            <MenuItem
                                                key={index}
                                                value={value.idTypeReservoir}
                                            >{value.nomTypeReservoir}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel>Format de voiture?</FormLabel>
                                <Select
                                    placeholder={"Vous etes plutot 4x4 ou berline?"}
                                    value={filterParams.idTypeVoiture}
                                    onChange={({ target }) => setfilterParams({ ...filterParams, idTypeVoiture: target.value })}
                                >
                                    <MenuItem
                                        value="aucun"
                                    >Tout</MenuItem>
                                    {
                                        typeVoitureListe.map((value, index) => (
                                            <MenuItem
                                                key={index}
                                                value={value.idTypeVoiture}
                                            >{value.nomTypeVoiture}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>

                        <ButtonGroup
                            variant='solid'
                        >
                            <Button
                                fullWidth
                                startDecorator={
                                    <FontAwesomeIcon icon={faRotateBack} />
                                }
                                color='neutral'
                                onClick={() => {
                                    setfilterParams(filterParamsDefaultValue);
                                    setvoitureAlouerListe(voitureAlouerListeOriginal);
                                    setvoitureAvendreListe(voitureAvendreListeOriginal);
                                    setisOpened(false);
                                }}
                            >
                                Remettre à zéro
                            </Button>
                            <Button
                                fullWidth
                                endDecorator={
                                    <FontAwesomeIcon icon={faCheck} />
                                }
                                color='primary'
                                onClick={() => {
                                    handlefilterData(
                                        filterParams,
                                        voitureAvendreListeOriginal,
                                        voitureAlouerListeOriginal,
                                        setvoitureAvendreListe,
                                        setvoitureAlouerListe,
                                    );

                                    setisOpened(false);
                                }}
                            >
                                Valider
                            </Button>
                        </ButtonGroup>
                    </Stack>
                )
            }

            <Button
                endDecorator={
                    <FontAwesomeIcon icon={faFilter} />
                }
                sx={{
                    width: "fit-content",
                    border: '4px solid black',
                    borderRadius: 10
                }}
                onClick={() => setisOpened(!isOpened)}
            >Filtrer</Button>
        </Stack >
    )
}

export default FiltreMaximifierZone