import { Card, CardContent, CardCover, Stack } from '@mui/joy'
import { COLOR, CONTACT, IMAGE } from '../../constants/constants'
import ContactZone from './ContactZone'
import MapZone from './MapZone'
import PartenaireZone from './PartenaireZone'


const Footer = () => {
    return (
        <Card
            sx={{
                p: 0,
                border: 0,
                borderRadius: 0,
            }}
        >
            <CardCover>
                <img
                    src={IMAGE.tourAfrique}
                    alt="Tour d'afrique. Karlo Car Location et achat de voiture à Bamako"
                />
            </CardCover>
            <CardCover
                sx={{
                    backgroundColor: "black",
                    opacity: "50%"
                }}
            ></CardCover>
            <CardContent>
                <Stack
                    direction="row"
                    flexWrap={window.innerWidth < 900 && "wrap"}
                    gap={1}
                    sx={{
                        p: 1,
                        "& > *": {
                            border: 0,
                            color: COLOR.falseBlack,
                            gap: 2,
                            // width:"30%",
                            // maxWidth: 500,
                            // minWidth: 330
                            minWidth: window.innerWidth < 900 ? "100%" : 330,
                            maxWidth: window.innerWidth < 900 ? "100%" : 500,
                        },
                    }}
                >
                    <ContactZone />
                    <MapZone />
                    <PartenaireZone />

                </Stack>

                <marquee
                    style={{
                        backgroundColor: COLOR.falseBlack,
                        color: "white"
                    }}
                >
                    © Karlo Car, 2024. Tous droits réservés.
                    Tous les contenus présents sur ce site web,
                    y compris : les textes, images, graphismes, et codes,
                    sont la propriété de Karlo Car et sont protégés par les lois internationales sur le droit d'auteur.
                    Toute utilisation, reproduction ou distribution non autorisée de tout ou partie de ce site web sans l'autorisation écrite préalable de Karlo Car est strictement interdite.
                    Pour toute demande de permission ou d'information,
                    veuillez nous contacter à {CONTACT.email}.
                </marquee>
            </CardContent>
        </Card>
    )
}

export default Footer