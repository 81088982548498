import { faCircleDot, faEnvelopeOpen, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Link, Stack, Typography } from "@mui/joy"
import { Divider, Drawer } from "@mui/material"
import { CONTACT, PAGE_LIST } from "../../constants/constants"
import PageLink from "./PageLink"
import { useLocation } from "react-router-dom"
import ContactSocialGroup from "../ContactSocialGroup/ContactSocialGroup"

const NavBar = ({ is_open = false, setisopen }) => {
  const { pathname } = useLocation();

  return (
    <Drawer
      open={is_open}
      anchor='right'
      SlideProps={{
        direction: "down",
      }}

      PaperProps={{
        style: {
          height: "fit-content",
          margin: 20,
          borderRadius: 10
        }
      }}
    >
      <Stack
        width={250}
        p={1}
      >
        <Button
          sx={{
            width: "fit-content",
            gap: 1,
            alignSelf: "flex-end",
            p: 1
          }}

          onClick={() => setisopen(false)}
        >
          Fermer

          <FontAwesomeIcon
            icon={faCircleDot}
            style={{
              cursor: "pointer"
            }}
            fontSize={25}
          />
        </Button>

        <Stack
          mt={2}
          gap={4}
        >
          <Stack
            gap={2}
          >
            <Typography
              fontWeight={700}
            >
              Naviguez entre les pages
              <Divider />
            </Typography>

            <Stack
              gap={1}
            >
              {
                PAGE_LIST.map((value, index) => (
                  <PageLink
                    name={value.pageName}
                    key={index}
                    icon={value.icon}
                    link={value.link}
                    is_active={pathname === value.link || pathname === `${value.link}/`}
                  />
                ))
              }
            </Stack>

          </Stack>

          <Stack
            gap={2}
          >
            <Typography
              fontWeight={700}
            >
              Contactez nous
              <Divider />
            </Typography>

            <Stack
              gap={1}
            >
              {
                CONTACT?.tel.map((value, index) => (
                  <Link
                    justifyContent={"space-between"}
                    key={index}
                    href={`tel:${value}`}
                  >
                    {value}
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </Link>
                ))
              }
              {
                CONTACT?.email && (
                  <Link
                    justifyContent={"space-between"}
                    href={`mailto:${CONTACT.email}`}
                  >
                    {CONTACT.email}
                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                  </Link>
                )
              }

              <ContactSocialGroup />
            </Stack>

          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default NavBar