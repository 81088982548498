import { CardMedia } from "@mui/material"

const MapZone = () => {
    return (
        <CardMedia
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15572.963724219479!2d-8.024654399999955!3d12.632193149999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe51cc5df81b5b11%3A0x572b6d6dbdd05f4!2sACI%202000%2C%20Bamako!5e0!3m2!1sfr!2sml!4v1722602168148!5m2!1sfr!2sml"
            component={"iframe"}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            sx={{
                borderRadius:"10px"
            }}
        />
    )
}

export default MapZone