import { COLOR, CONTACT } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faCarAlt, faPaperPlane, faPhone, faTriangleExclamation, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from '@mui/material';
import { USE_STATE_T, VOITURE_A_LOUER_T, VOITURE_A_VENDRE_T } from '../../constants/objectType';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { CircularProgress, Input, Stack, Typography } from '@mui/joy';
import { addReservation } from '../../functions/api/voitureALouer/addReservation';

const ReservationBtnZone = ({ voiture, isALouer }: { voiture: VOITURE_A_VENDRE_T | VOITURE_A_LOUER_T, isALouer: boolean }) => {
    const [open, setOpen] = useState(false);
    const [userNumber, setuserNumber]: [userNumber: null | string, setuserNumber: USE_STATE_T] = useState(localStorage.getItem("userNumber"));
    const [etatDemandeReservation, setetatDemandeReservation]:
        [etatDemandeReservation: string, setetatDemandeReservation: USE_STATE_T] = useState("pas d'envoie en cours");

    const handleEnvoieDemande = () => {
        if (userNumber) {
            setetatDemandeReservation("en cours d'envoie");
            addReservation(userNumber, voiture).then(
                () => {
                    setetatDemandeReservation("Envoyé");
                    localStorage.setItem("userNumber", userNumber);
                    alert('Un agent vous contactera bientot');
                }
            ).catch(
                () => {
                    setetatDemandeReservation('echouée');
                    alert(`Envoie echoué. Veuillez réessayer ulterieurement ou appeller notre service client au ${CONTACT.tel}`);
                }
            ).finally(
                // () => window.location.reload()
            )
        }
    }

    return (
        <>
            <Button
                sx={{
                    borderRadius: 0,
                    bgcolor: COLOR.primaryBlue,
                    color: "white",
                    textTransform: "none",
                    ":hover": {
                        bgcolor: COLOR.primaryBlue,
                        color: "white",
                        textTransform: "none"
                    },
                    gap: 1
                }}
                onClick={() => { setOpen(true) }}
            >
                {isALouer ? "Reserver" : "Acheter"} maintenant
                <FontAwesomeIcon icon={isALouer ? faCalendarPlus : faCarAlt} />
            </Button>
            <Button
                sx={{
                    borderRadius: 0,
                    bgcolor: COLOR.primaryBlue,
                    color: "white",
                    textTransform: "none",
                    ":hover": {
                        bgcolor: COLOR.primaryBlue,
                        color: "white",
                        textTransform: "none"
                    },
                    gap: 1
                }}
                target='_blank'
                href={`https://wa.me/${CONTACT.whatsapp}?text=${window.location.href}%0ABonjours%20je%20viens%20de%20votre%20site%20web%20Karlo%20Car%20et%20je%20suis%20interessé%20par%20votre%20${voiture.modele}%20${voiture.annee}%20qui%20coute%20${voiture.prixAugmente.toLocaleString()}%20FCFA.`}
            >
                Discuter avec un agent
                <FontAwesomeIcon icon={faUserAstronaut} />
            </Button>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Stack
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: 300,
                        bgcolor: COLOR.falseBlack,
                        border: `1px solid ${COLOR.white}`,
                        borderRadius: 15,
                        p: 2,
                    }}
                    gap={2}
                >
                    <Typography
                        textColor={COLOR.white}
                        alignSelf={"center"}
                    >
                        {
                            etatDemandeReservation == "en cours d'envoie"
                                ? (
                                    <CircularProgress />
                                )
                                : etatDemandeReservation == 'echouée'
                                    ? `Envoie echoué. Veuillez réessayer ulterieurement ou appeller notre service client au ${CONTACT.tel}`
                                    : `Saisissez votre numero pour passer une reservation`
                        }

                    </Typography>

                    <Input
                        placeholder='Votre telephone'
                        startDecorator={
                            <FontAwesomeIcon icon={faPhone} />
                        }
                        onChange={({ target }) => setuserNumber(target.value)}
                        disabled={etatDemandeReservation == "en cours d'envoie"}
                    />

                    <ButtonGroup>
                        <Button
                            fullWidth
                            color='error'
                            sx={{
                                gap: 1
                            }}
                            onClick={() => setOpen(false)}
                        >
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            Annuler
                        </Button>
                        <Button
                            fullWidth
                            color='success'
                            variant='contained'
                            sx={{
                                gap: 1
                            }}
                            onClick={() => handleEnvoieDemande()}
                        >
                            Valider
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                    </ButtonGroup>
                </Stack>
            </Modal>
        </>
    )
}

export default ReservationBtnZone