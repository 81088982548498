import { Container, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"
import VoitureCard from "../../components/VoitureCard/VoitureCard"
import { useCallback, useEffect, useState } from "react"
import { getAllVoituresAVendre } from "../../functions/api/voitureAVendre/getAllVoituresAVendre"
import { getAllVoituresALouer } from "../../functions/api/voitureALouer/getAllVoituresALouer"
import { USE_STATE_T, VOITURE_A_LOUER_T, VOITURE_A_VENDRE_T } from "../../constants/objectType"
import { useParams } from "react-router-dom"
import LoadingCard from "../../components/LoadingCard/LoadingCard"
import FiltreMaximifierZone from "./FiltreMaxifier"
import { Helmet } from "react-helmet"

export interface filterParams_T {
  prixMin: number | undefined,
  prixMax: number | undefined,
  nomVoiture: string | undefined,
  annee: number | undefined,
  idMarque: string | null,
  isAVendre: boolean | null,
  isAutomatique: boolean | null,
  idTypeReservoire: string | null,
  idTypeVoiture: string | null,
}

const Garage = () => {
  const { prixMax, typeVoiture, idMarque } = useParams();

  const [voitureAvendreListeOriginal, setvoitureAvendreListeOriginal]: [voitureAvendreListeOriginal: VOITURE_A_VENDRE_T[], setvoitureAvendreListeOriginal: USE_STATE_T] = useState([]);
  const [voitureAvendreListe, setvoitureAvendreListe]: [voitureAvendreListe: VOITURE_A_VENDRE_T[], setvoitureAvendreListe: USE_STATE_T] = useState([]);
  const [voitureAlouerListeOriginal, setvoitureAlouerListeOriginal]: [voitureAlouerListeOriginal: VOITURE_A_LOUER_T[], setvoitureAlouerListeOriginal: USE_STATE_T] = useState([]);
  const [voitureAlouerListe, setvoitureAlouerListe]: [voitureAlouerListe: VOITURE_A_LOUER_T[], setvoitureAlouerListe: USE_STATE_T] = useState([]);

  const [isLoading, setisLoading]: [isLoading: boolean | null, setisLoading: USE_STATE_T] = useState(true);

  const filterParamsDefaultValue = {
    prixMin: undefined,
    prixMax: prixMax && prixMax != "null" ? parseInt(prixMax) : undefined,
    nomVoiture: undefined,
    annee: undefined,
    idMarque: idMarque || null,
    isAVendre: typeVoiture == "a vendre"
      ? true
      : typeVoiture == "a louer"
        ? false
        : null,
    isAutomatique: null,
    idTypeReservoire: "aucun",
    idTypeVoiture: "aucun",
  };

  const [filterParams, setfilterParams]:
    [
      filterParams: filterParams_T,
      setfilterParams: USE_STATE_T
    ] = useState(filterParamsDefaultValue);

  const loadVoiture = useCallback(async () => {
    try {
      let data: VOITURE_A_VENDRE_T[] | VOITURE_A_LOUER_T[] | undefined = await getAllVoituresAVendre();
      setvoitureAvendreListe(data.filter(value => !(value.isVendu)));
      setvoitureAvendreListeOriginal(data.filter(value => !(value.isVendu)));

      data = await getAllVoituresALouer();
      setvoitureAlouerListe(data.filter(value => value.isDisponible));
      setvoitureAlouerListeOriginal(data.filter(value => value.isDisponible));

      // handlefilterData(
      //   await filterParams,
      //   await voitureAvendreListeOriginal,
      //   await voitureAlouerListeOriginal,
      //   await setvoitureAvendreListe,
      //   await setvoitureAlouerListe,
      // );
    } catch (error) { }

    setisLoading(false);


  }, []);

  useEffect(() => {
    loadVoiture();

    // loadVoitureALouer();
  }, []);

  // useEffect(
  //   () => {
  //     getAllVoituresAVendre().then(
  //       data => {
  //         setvoitureAvendreListe(data.filter(value => !(value.isVendu)));
  //         setvoitureAvendreListeOriginal(data.filter(value => !(value.isVendu)));
  //       }
  //     ).finally(
  //       () => {
  //         setisLoading(false);
  //         handlefilterData(
  //           filterParams,
  //           voitureAvendreListeOriginal,
  //           voitureAlouerListeOriginal,
  //           setvoitureAvendreListe,
  //           setvoitureAlouerListe,
  //         );
  //       }
  //     );
  //     getAllVoituresALouer().then(
  //       data => {
  //         setvoitureAlouerListe(data.filter(value => value.isDisponible));
  //         setvoitureAlouerListeOriginal(data.filter(value => value.isDisponible));
  //       }
  //     ).finally(
  //       () => {
  //         setisLoading(false);
  //         handlefilterData(
  //           filterParams,
  //           voitureAvendreListeOriginal,
  //           voitureAlouerListeOriginal,
  //           setvoitureAvendreListe,
  //           setvoitureAlouerListe,
  //         );
  //       }
  //     );
  //   },
  //   []
  // );

  return (
    <Container>
      {/* <FiltreMinfierZone /> */}
      <Helmet>
        <title>Recherchez et trouvez la voiture idéale en quelque clique. Karlo Car Site web de location et achat de voiture à Bamako Mali.</title>
        <meta name="description" content="Recherchez et trouvez la voiture idéale en quelque clique. Karlo Car Site web de location et achat de voiture à Bamako Mali." />
      </Helmet>

      <Stack
        gap={5}
        mb={3}
      >
        <Typography level="h1" textColor={COLOR.white}>
          Trouvez votre voiture de reve en un temps record
        </Typography>

        <Stack
          gap={4}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {
            isLoading
              ? (
                <LoadingCard
                  height={undefined}
                  width={undefined}
                />
              )
              :
              <>
                {
                  filterParams.isAVendre != false && voitureAvendreListe.slice(0, 16)
                    .map((value, index) => (
                      <VoitureCard
                        nomMarque={value?.marque.nomMarque}
                        img={`${process.env.REACT_APP_API_URL}/voitureVendre/${value.idVoiture}/images/${value.images[0]}`}
                        nom={value.modele}
                        isALouer={false}
                        prix={`${value.prixAugmente.toLocaleString()}`}
                        height={220}
                        marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                        width={220}
                        key={index}
                        id={value.idVoiture}
                      />
                    ))
                }
                {
                  filterParams.isAVendre != true && voitureAlouerListe.slice(0, 16)
                    .map((value, index) => (
                      <VoitureCard
                        nomMarque={value?.marque.nomMarque}
                        img={`${process.env.REACT_APP_API_URL}/voitureLouer/${value.idVoiture}/images/${value.images[0]}`}
                        nom={value.modele}
                        isALouer={true}
                        prix={`${value.prixAugmente.toLocaleString()}`}
                        marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                        height={220}
                        width={220}
                        key={index}
                        id={value.idVoiture}
                      />
                    ))
                }
              </>
          }

        </Stack>

        {/* <Pagination
          count={(voitureAvendreListe.length + voitureAlouerListe.length) / 32 + 1}
          shape="rounded"
          sx={{
            alignSelf: "center",
            bgcolor: "white",
            borderRadius: 10
          }}
        /> */}
      </Stack>

      <FiltreMaximifierZone
        filterParams={filterParams}
        setfilterParams={setfilterParams}
        setvoitureAlouerListe={setvoitureAlouerListe}
        setvoitureAvendreListe={setvoitureAvendreListe}
        voitureAlouerListeOriginal={voitureAlouerListeOriginal}
        voitureAvendreListeOriginal={voitureAvendreListeOriginal}
        filterParamsDefaultValue={filterParamsDefaultValue}
      />

    </Container>
  )
}

export default Garage