import { Box, Button, Card, CardCover, Link, Stack, Typography } from '@mui/joy'
import { COLOR, IMAGE } from '../../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'

const PartenaireZone = () => {
    return (
        <Box
        >
            <Stack
                height={"95%"}
                justifyContent={"space-between"}
                bgcolor={COLOR.darkBlue}
                p={1}
                borderRadius={10}
                gap={1}
            >
                <Typography
                    textColor={COLOR.white}
                    level='h3'
                    alignSelf={"flex-start"}
                >
                    Devenez notre partenaire.
                </Typography>
                <Typography
                    sx={{
                        borderLeft: `2px solid white`,
                        pl: 1,
                        textAlign: "justify"
                    }}
                    textColor={COLOR.white}
                    fontSize={11}
                >
                    Vous avez des voitures à vendre ou à louer? Rejoignez nous pour booster votre chiffre d'affaire.
                    {` `}<Link
                        sx={{
                            color: "white",
                        }}
                        href='/about'
                    >
                        <u>Cliquez ici</u>
                    </Link>{` `}
                    pour lire nos conditions de partenariat.
                </Typography>
                <Card
                    sx={{
                        width: "100%",
                        p: 0,
                        height: 160
                    }}
                >
                    <CardCover>
                        <img
                            src={IMAGE.rangeRoverFooterback}
                            alt="range rover disponible chez Karlo Car. Karlo Car Location et achat de voiture à Bamako"
                        />
                    </CardCover>
                </Card>

                <Button
                    fullWidth
                    endDecorator={
                        <FontAwesomeIcon icon={faHandshake} />
                    }
                >
                    Lancer une demande de partenariat
                </Button>
            </Stack >
        </Box>

    )
}

export default PartenaireZone