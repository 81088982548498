import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Link, Stack, Typography } from '@mui/joy';
import { COLOR, IMAGE } from '../../constants/constants';
import { useEffect, useState } from 'react';
import { getVoitureAVendre } from '../../functions/api/voitureAVendre/getVoitureAVendre';
import { getVoitureALouer } from '../../functions/api/voitureALouer/getVoitureALouer';
import { Button, CardMedia } from '@mui/material';
import ImageZone from './ImageZone';
import LoadingCard from '../../components/LoadingCard/LoadingCard';
import ReservationBtnZone from './ReservationBtnZone';
import { Helmet } from 'react-helmet';

const Voiture = () => {
    const { idVoiture, isALouer } = useParams();
    const [voiture, setvoiture] = useState(null);

    useEffect(
        () => {
            if (idVoiture) {
                if (isALouer) {
                    getVoitureAVendre(idVoiture).then(
                        data => {
                            setvoiture(data);
                        }
                    ).catch(
                        () => {
                            window.location.href = "/garage";
                        }
                    )
                }
                else {
                    getVoitureALouer(idVoiture).then(
                        data => {
                            setvoiture(data);
                        }
                    ).catch(
                        () => {
                            window.location.href = "/garage";
                        }
                    )
                }
            }
        },
        []
    )

    return (
        <Box
            sx={{
                "& *": {
                    borderRadius: 0,
                }
            }}
        >
            <Box
                sx={{
                    bgcolor: "white",
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    flexWrap: window.innerWidth < 800 ? "wrap" : "nowrap",
                    p: 2,
                }}
            >
                {
                    !voiture
                        ? (
                            <LoadingCard
                                width={undefined}
                                height={undefined}
                            />
                        )
                        : (
                            <>
                                <Helmet>
                                    <title>{voiture.modele} {voiture?.annee} à vendre et à louer chez Karlo Car. </title>
                                    <meta name="description" content={`${voiture.modele} ${voiture?.annee} à vendre et à louer chez Karlo Car. `} />
                                    <meta property="og:image" content="favicon.ico" />
                                    <link rel="apple-touch-icon" href="favicon.ico" />
                                    <link rel="icon" type="image/x-icon" href="favicon.ico" />
                                </Helmet>

                                <ImageZone
                                    images={voiture?.images}
                                    isALouer={isALouer}
                                    idVoiture={idVoiture}
                                    idMarque={voiture?.marque.idMarque}
                                    nomMarque={voiture?.marque.nomMarque}
                                    modele={voiture.modele}
                                />

                                <Stack
                                    maxWidth={600}
                                    minWidth={window.innerWidth < 500 ? 300 : 500}
                                    gap={2}
                                >
                                    <Stack
                                        sx={{
                                            bgcolor: COLOR.falseBlack,
                                            p: 1
                                        }}
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Typography
                                            level='h3'
                                            textColor='white'
                                        >
                                            {voiture?.modele}
                                        </Typography>
                                        <Button
                                            disabled
                                            sx={{
                                                color: COLOR.falseBlack,
                                                borderRadius: 0,
                                                bgcolor: "white"
                                            }}
                                        >{voiture?.annee}</Button>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        flexWrap={"wrap"}
                                        gap={1}
                                        justifyContent={"space-between"}
                                        sx={{
                                            "& > *": {
                                                width: window.innerWidth < 500 ? "100%" : "43%",
                                                maxWidth: 360,
                                                minWidth: 200,
                                                borderRadius: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flexWrap: "nowrap"
                                            }
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: window.innerWidth < 500 ? "100%" : "43%",
                                                maxWidth: 360,
                                                minWidth: 200,
                                                borderRadius: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flexWrap: "nowrap"
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    fontWeight={700}
                                                >
                                                    Type de boite
                                                </Typography>
                                                <Typography>
                                                    {voiture?.typeBoite}
                                                </Typography>
                                            </CardContent>
                                            <CardMedia
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                component={"img"}
                                                src={IMAGE.boiteVitesse}
                                                alt={`Type de boite de vitesse de la voiture. Location et achat de voiture à Bamako`}
                                            />
                                        </Card >
                                        <Card
                                            sx={{
                                                width: window.innerWidth < 500 ? "100%" : "43%",
                                                maxWidth: 360,
                                                minWidth: 200,
                                                borderRadius: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flexWrap: "nowrap"
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    fontWeight={700}
                                                >
                                                    Type de reservoir
                                                </Typography>
                                                <Typography>
                                                    {voiture?.typeReservoir.nomTypeReservoir}
                                                </Typography>
                                            </CardContent>
                                            <CardMedia
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                component={"img"}
                                                src={IMAGE.essenceIcon}
                                                alt={`Type de moteur de la voiture. Location et achat de voiture à Bamako`}
                                            />
                                        </Card >
                                        <Card
                                            sx={{
                                                width: window.innerWidth < 500 ? "100%" : "43%",
                                                maxWidth: 360,
                                                minWidth: 200,
                                                borderRadius: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flexWrap: "nowrap"
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    fontWeight={700}
                                                >
                                                    Type de voiture
                                                </Typography>
                                                <Typography>
                                                    {voiture?.typeVoiture.nomTypeVoiture}
                                                </Typography>
                                            </CardContent>
                                            <CardMedia
                                                sx={{
                                                    width: 60,
                                                    height: 50,
                                                }}
                                                component={"img"}
                                                src={IMAGE.typeVoitureIcone}
                                                alt={`Type de la voiture. Location et achat de voiture à Bamako`}
                                            />
                                        </Card >
                                        <Card
                                            sx={{
                                                width: window.innerWidth < 500 ? "100%" : "43%",
                                                maxWidth: 360,
                                                minWidth: 200,
                                                borderRadius: 0,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                flexWrap: "nowrap"
                                            }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    fontWeight={700}
                                                >
                                                    Nombre portière
                                                </Typography>
                                                <Typography>
                                                    {voiture?.nbPortiere}
                                                </Typography>

                                            </CardContent>
                                            <CardMedia
                                                sx={{
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                component={"img"}
                                                src={IMAGE.carDoorIcone}
                                                alt={`Nombre de porte de la voiture. Location et achat de voiture à Bamako`}
                                            />
                                        </Card >
                                    </Stack>
                                    <Typography
                                        sx={{
                                            borderLeft: `10px solid ${COLOR.falseBlack}`,
                                            pl: 2,
                                            textAlign: "justify"
                                        }}
                                    >
                                        En confirmant votre réservation,
                                        vous acceptez expressément <Link> nos conditions d'utilisation </Link>.
                                        Nous vous invitons à lire attentivement ces conditions avant de compléter votre réservation.
                                    </Typography>

                                    <ReservationBtnZone
                                        voiture={voiture}
                                        isALouer={isALouer}
                                    />
                                </Stack>
                            </>
                        )
                }
            </Box>
        </Box>
    )
}

export default Voiture