import { filterParams_T } from "../..";
import { USE_STATE_T, VOITURE_A_LOUER_T, VOITURE_A_VENDRE_T } from "../../../../constants/objectType";

export const handlefilterData = (
    filterParams: filterParams_T,
    voitureAvendreListeOriginal: VOITURE_A_VENDRE_T[],
    voitureAlouerListeOriginal: VOITURE_A_LOUER_T[],
    setvoitureAvendreListe: USE_STATE_T,
    setvoitureAlouerListe: USE_STATE_T
) => {
    let aVendreTmp = voitureAvendreListeOriginal.filter(
        (value) => {
            let res = true;

            if (!(
                value.prixAugmente >= (filterParams.prixMin || 0)
                && value.prixAugmente <= (filterParams.prixMax || 999999999)
            )) {
                res = false;
            }

            if (filterParams.annee) {
                if (!(
                    value?.annee >= filterParams.annee - 2
                    && value?.annee <= filterParams.annee + 2
                )) {
                    res = false;
                }
            }

            if (filterParams.nomVoiture) {
                if (!(
                    value?.modele?.toLowerCase()
                        ?.includes(filterParams.nomVoiture.toLowerCase())
                )) {
                    res = false;
                }
            }

            if (filterParams.idMarque) {
                if (value?.marque.idMarque != filterParams.idMarque) {
                    res = false;
                }
            }

            if (filterParams.isAutomatique) {
                if (value?.typeBoite != (filterParams.isAutomatique ? "automatic" : "manuel")) {
                    res = false;
                }
            }

            if (filterParams.idTypeReservoire) {
                if (filterParams.idTypeReservoire != 'aucun' && value?.typeReservoir.idTypeReservoir != filterParams.idTypeReservoire) {
                    res = false;
                }
            }

            if (filterParams.idTypeVoiture) {
                if (filterParams.idTypeVoiture != 'aucun' && value?.typeVoiture.idTypeVoiture != filterParams.idTypeVoiture) {
                    res = false;
                }
            }

            return res;
        }
    );

    let aLouerTmp = voitureAlouerListeOriginal.filter(
        (value) => {
            let res = true;

            if (!(
                value.prixAugmente >= (filterParams.prixMin || 0)
                && value.prixAugmente <= (filterParams.prixMax || 1999999999)
            )) {
                res = false;
            }

            if (filterParams.annee) {
                if (!(
                    value?.annee >= filterParams.annee - 2
                    && value?.annee <= filterParams.annee + 2
                )) {
                    res = false;
                }
            }

            if (filterParams.nomVoiture) {
                if (!(
                    value?.modele?.toLowerCase()
                        ?.includes(filterParams.nomVoiture.toLowerCase())
                )) {
                    res = false;
                }
            }

            if (filterParams.idMarque) {
                if (value?.marque.idMarque != filterParams.idMarque) {
                    res = false;
                }
            }

            if (filterParams.isAutomatique) {
                if (value?.typeBoite != (filterParams.isAutomatique ? "automatic" : "manuel")) {
                    res = false;
                }
            }

            if (filterParams.idTypeReservoire && filterParams?.idTypeReservoire != "aucun") {
                if (value?.typeReservoir.idTypeReservoir != filterParams.idTypeReservoire) {
                    res = false;
                }
            }

            if (filterParams.idTypeVoiture && filterParams?.idTypeVoiture != "aucun") {
                if (value?.typeVoiture.idTypeVoiture == filterParams.idTypeVoiture) {
                    res = false;
                }
            }

            return res;
        }
    );

    setvoitureAvendreListe(aVendreTmp);
    setvoitureAlouerListe(aLouerTmp);
}