import { Link, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"
import VoitureCard from "../../components/VoitureCard/VoitureCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons"
import { Avatar } from "@mui/material"
import { useEffect, useState } from "react"
import { VOITURE_A_LOUER_T, VOITURE_A_VENDRE_T, USE_STATE_T } from "../../constants/objectType"
import { getAllvoituresALouerByNbView } from "../../functions/api/voitureALouer/getAllvoituresALouerByNbView"
import { getAllvoituresAVendreByNbView } from "../../functions/api/voitureAVendre/getAllvoituresAVendreByNbView"
import LoadingCard from "../../components/LoadingCard/LoadingCard"

const PopularZone = () => {
    const [listeVoitureAvendre, setlisteVoitureAvendre]: [listeVoiture: VOITURE_A_VENDRE_T[], setlisteVoiture: USE_STATE_T] = useState([]);
    const [listeVoitureAlouer, setlisteVoitureAlouer]: [listeVoiture: VOITURE_A_LOUER_T[], setlisteVoiture: USE_STATE_T] = useState([]);

    const [isLoading, setisLoading]: [isLoading: boolean | null, setisLoading: USE_STATE_T] = useState(null);

    useEffect(
        () => {
            getAllvoituresAVendreByNbView().then(
                data => {
                    setlisteVoitureAvendre(data.filter(value => !(value.isVendu)).slice(0, 6));
                }
            ).finally(
                () => setisLoading(false)
            );
            getAllvoituresALouerByNbView().then(
                data => {
                    setlisteVoitureAlouer(data.filter(value => value.isDisponible).slice(0, 6));
                }
            ).finally(
                () => setisLoading(false)
            );
        },
        []
    )

    return (
        isLoading
            ? (
                <LoadingCard
                    width={undefined}
                    height={undefined}
                />
            )
            : (
                < Stack
                    gap={5}
                >
                    <Typography level="h1" textColor={COLOR.white}>
                        Decouvrez nos voitures les plus populaires
                    </Typography>

                    <Stack
                        gap={4}
                        direction={"row"}
                        flexWrap={"wrap"}
                        justifyContent={"center"}
                    >
                        {listeVoitureAvendre.map((value, index) => (
                            <VoitureCard
                                nomMarque={value?.marque.nomMarque}
                                img={`${process.env.REACT_APP_API_URL}/voitureVendre/${value.idVoiture}/images/${value.images[0]}`}
                                nom={value.modele}
                                isALouer={false}
                                prix={`${value.prixAugmente.toLocaleString()}`}
                                height={undefined}
                                marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                                width={undefined}
                                key={index}
                                id={value.idVoiture}
                            />
                        ))
                        }
                        {
                            listeVoitureAlouer.map((value, index) => (
                                <VoitureCard
                                    nomMarque={value?.marque.nomMarque}
                                    img={`${process.env.REACT_APP_API_URL}/voitureLouer/${value.idVoiture}/images/${value.images[0]}`}
                                    nom={value.modele}
                                    isALouer={true}
                                    prix={`${value.prixAugmente.toLocaleString()}`}
                                    height={undefined}
                                    marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                                    width={undefined}
                                    key={index}
                                    id={value.idVoiture}
                                />
                            ))
                        }

                    </Stack>
                    <Link
                        sx={{
                            alignSelf: "flex-end",
                        }}
                        href="/garage/"
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                            p={1}
                            bgcolor={COLOR.white}
                            borderRadius={10}
                        >
                            <Typography
                                fontWeight={700}
                            >Voir plus</Typography>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    bgcolor: COLOR.lightblue,
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowUpWideShort} />
                            </Avatar>
                        </Stack>
                    </Link>
                </Stack >
            )
    )
}

export default PopularZone