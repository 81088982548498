import { Link, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"
import VoitureCard from "../../components/VoitureCard/VoitureCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpWideShort } from "@fortawesome/free-solid-svg-icons"
import { Avatar } from "@mui/material"
import { useEffect, useState } from "react"
import { getAllvoituresAVendreByPrixMoinsChere } from "../../functions/api/voitureAVendre/getAllvoituresAVendreByPrixMoinsChere"
import { VOITURE_A_LOUER_T, USE_STATE_T } from "../../constants/objectType"
import { getAllvoituresALouerByPrixMoinsChere } from "../../functions/api/voitureALouer/getAllvoituresALouerByPrixMoinsChere"
import LoadingCard from "../../components/LoadingCard/LoadingCard"

const PrixbasZone = () => {
  const [listeVoitureAvendre, setlisteVoitureAvendre]: [listeVoitureAvendre: VOITURE_A_LOUER_T[], setlisteVoitureAvendre: USE_STATE_T] = useState([]);
  const [listeVoitureAlouer, setlisteVoitureAlouer]: [listeVoitureAlouer: VOITURE_A_LOUER_T[], setlisteVoitureAlouer: USE_STATE_T] = useState([]);

  const [isLoading, setisLoading]: [isLoading: boolean | null, setisLoading: USE_STATE_T] = useState(null);

  useEffect(
    () => {
      getAllvoituresAVendreByPrixMoinsChere().then(
        data => {
          setlisteVoitureAvendre(data.filter(value => !(value.isVendu)).slice(0, 6));
        }
      ).finally(
        () => setisLoading(false)
      );
      getAllvoituresALouerByPrixMoinsChere().then(
        data => {
          setlisteVoitureAlouer(data.filter(value => value.isDisponible).slice(0, 6));
        }
      ).finally(
        () => setisLoading(false)
      );
    },
    []
  )

  return (
    isLoading
      ? (
        <LoadingCard
          width={undefined}
          height={undefined}
        />
      )
      : (
        <Stack
          gap={5}
        >
          <Typography level="h1" textColor={COLOR.white}>
            Nos recommandation pour des budgets moderés
          </Typography>

          <Stack
            gap={4}
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            {
              listeVoitureAvendre.map((value, index) => (
                <VoitureCard
                  nomMarque={value?.marque.nomMarque}
                  img={`${process.env.REACT_APP_API_URL}/voitureVendre/${value.idVoiture}/images/${value.images[0]}`}
                  nom={value.modele}
                  isALouer={false}
                  prix={`${value.prixAugmente.toLocaleString()}`}
                  height={undefined}
                  marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                  width={undefined}
                  key={index}
                  id={value.idVoiture}
                />
              ))
            }
            {
              listeVoitureAlouer.map((value, index) => (
                <VoitureCard
                  nomMarque={value?.marque.nomMarque}
                  img={`${process.env.REACT_APP_API_URL}/voitureLouer/${value.idVoiture}/images/${value.images[0]}`}
                  nom={value.modele}
                  isALouer={true}
                  prix={`${value.prixAugmente.toLocaleString()}`}
                  height={undefined}
                  marqueImage={`${process.env.REACT_APP_API_URL}/marque/${value.marque.idMarque}/image`}
                  width={undefined}
                  key={index}
                  id={value.idVoiture}
                />
              ))
            }
          </Stack>
          <Link
            sx={{
              alignSelf: "flex-end",
            }}
            href="/garage/"
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              p={1}
              bgcolor={COLOR.white}
              borderRadius={10}
            >
              <Typography
                // textColor={"common.white"}
                fontWeight={700}
              >Voir plus</Typography>
              <Avatar
                variant="rounded"
                sx={{
                  bgcolor: COLOR.lightblue,
                }}
              >
                <FontAwesomeIcon icon={faArrowUpWideShort} />
              </Avatar>
            </Stack>
          </Link>
        </Stack>
      )
  )
}

export default PrixbasZone