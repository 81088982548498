import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Stack } from "@mui/joy"
import { COLOR, CONTACT } from "../../constants/constants"
import { faFacebookF, faInstagram, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ContactSocialGroup = ({ mt, iconWidth, iconSize }) => {
    return (
        <Stack
            direction={"row"}
            gap={1}
            mt={mt ?? 2}
            sx={{
                "& *:is(div)": {
                    width: iconWidth || 40,
                    aspectRatio: "1/1",
                    fontSize: iconSize || 27,
                    justifyContent: "center",
                    borderRadius: 10,
                }
            }}
        >
            {
                CONTACT?.whatsapp && (
                    <Link
                        href={CONTACT.whatsapp}
                        target={"_blank"}
                    >
                        <Stack
                            bgcolor={COLOR.green}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faWhatsapp} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.facebook && (
                    <Link
                        href={CONTACT.facebook}
                        target={"_blank"}
                    >
                        <Stack
                            bgcolor={COLOR.darkBlue}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faFacebookF} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.instagram && (
                    <Link
                        href={CONTACT.instagram}
                        target={"_blank"}
                    >
                        <Stack
                            sx={{
                                background: `linear-gradient(45deg,${COLOR.red}, ${COLOR.primaryBlue}, ${COLOR.yellow})`
                            }}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faInstagram} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.titkok && (
                    <Link
                        href={CONTACT.titkok}
                        target={"_blank"}
                    >
                        <Stack
                            bgcolor={COLOR.black}
                        >
                            <FontAwesomeIcon color={COLOR.red} icon={faTiktok} />
                        </Stack>
                    </Link>
                )
            }
        </Stack >
    )
}

export default ContactSocialGroup