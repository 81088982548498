import axios from "axios";
import { GET_ALL_MARQUES_T } from "../../../constants/objectType";

/**
 * Retrieves a list of all marques from the API.
 *
 * @returns {Promise<GET_ALL_MARQUES_T[]>} A promise that resolves with an array of marques.
 * @example
 * ```javascript
 * import { getAllMarques } from './marqueService';
 *
 * getAllMarques()
 *   .then((marques) => console.log(marques))
 *   .catch((error) => console.error(error));
 * ```
 */
export const getAllMarques = (): Promise<GET_ALL_MARQUES_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/marque/getAllMarque`).then(
            ({ data }: { data: GET_ALL_MARQUES_T[] }) => {
                console.log('====================================');
                console.log("La liste des marques", data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (res) => {
                console.log('====================================');
                console.error(res);
                console.log('====================================');

                reject(res);
            }
        )
    });
}