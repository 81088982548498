import axios from "axios";
import { VOITURE_A_LOUER_T } from "../../../constants/objectType";

/**
 *
 * @returns {Promise<VOITURE_A_LOUER_T[]>} A promise that resolves with an array of voitures à vendre objects.
 *
 * @example
 * ```
 * import { getAllVoituresAVendre } from './api';
 *
 * getAllVoituresAVendre().then((voitures) => {
 *   console.log(voitures); // Output: Array of voitures à vendre objects
 * }).catch((error) => {
 *   console.error(error);
 * });
 * ```
 */
export const getAllvoituresALouerByNbView = (): Promise<VOITURE_A_LOUER_T[]> => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/voitureVendre/getAllByPrixMoinsChere`).then(
            ({ data }: { data: VOITURE_A_LOUER_T[] }) => {
                console.log('====================================');
                console.log("La liste des voitures a louer les plus populaires", data);
                console.log('====================================');

                resolve(data);
            }
        ).catch(
            (res) => {
                console.log('====================================');
                console.error(res);
                console.log('====================================');

                reject(res);
            }
        )
    });
}